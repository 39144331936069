  .home_body__btn__hover:hover {
    background: #2F2E80 !important;
  }

  .header_home_body__btn__hover:hover {
    color: #2F2E80 !important;
  }


  .button__header{
    justify-content: center;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
    align-items: center;
  }